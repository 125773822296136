import React from "react";
import Healthcarebg from "./healthcare.jpg";
export default function PrivacyPolicy() {
  const imageUrl = Healthcarebg;
  return (
    <div className="pt-16 px-10">
      <div>
        <h1 className="text-3xl fot-bold font-bold pt-3 text-gray-800">
          Mindcom Privacy Policy
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          This Privacy Policy applies to all websites owned or operated by
          Mindcom Technology ("Mindcom"), including the site you are currently
          viewing and any other Mindcom sites to which you may link
          (collectively, the “Mindcom Sites”). By visiting or using any Mindcom
          Sites, you consent to the collection, use, and transfer of your
          personal information as described in this policy. We do not share your
          information with third-party entities.
        </p>
      </div>

      <div>
        <h1 className="text-lg font-bold text-gray-800">
          1. Collection of Information
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">A. Purpose:</span>{" "}
          Users can access certain features without disclosing personal
          information. Registration may be required for specific content.
        </p>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">
            B. Information During Registration:
          </span>{" "}
          Mindcom may collect information such as your name, phone number, email
          address, geographic location, age, demographic information, and
          browser history.
        </p>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">
            C. Automatically Collected Information:
          </span>{" "}
          Mindcom may automatically collect information about your computer
          hardware and software, including IP address, browser type, access
          times, and referring website addresses.
        </p>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">
            D. Storage and Transfer:
          </span>{" "}
          Information may be stored and processed in the United States or other
          locations where Mindcom or its affiliates operate. By using Mindcom
          Sites, you consent to such transfers
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">
          2. Use and Sharing of Information
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">
            A. Operations and Services:
          </span>{" "}
          Mindcom uses information to operate websites and provide services,
          including sending newsletters and offers. Geographical information may
          be used to provide localized content.
        </p>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">
            B. Third Parties:
          </span>{" "}
          Mindcom may share information with affiliates and third parties for
          business purposes. Third parties providing services on behalf of
          Mindcom may have access to necessary information but are generally
          restricted from using it for other purposes.
        </p>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">
            C. Legal Requirements and Protection:
          </span>{" "}
          Mindcom may disclose information as required by law or to protect the
          rights, property, or safety of Mindcom, its users, or others.
        </p>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">
            D. Third-Party Links:
          </span>{" "}
          Mindcom Sites may link to third-party websites. Mindcom is not
          responsible for the privacy practices or content of those sites.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">
          3. Information from Other Sources
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          Mindcom may supplement collected information with data from other
          sources, such as updated addresses, purchase information, and search
          results, to improve services and prevent fraud.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">
          4. Information Security
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          Mindcom requires password protection for certain site areas. Users are
          responsible for maintaining password confidentiality and notifying
          Mindcom of any unauthorized use.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">5. Use of Cookies</h1>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">A. Purpose:</span>{" "}
          Cookies enhance user experience by storing preferences and enabling
          automatic login.
        </p>
        <p className="text-lg text-gray-600 pl-3">
          <span className="text-lg font-bold text-black">
            B. AdWords Remarketing:
          </span>{" "}
          Mindcom uses cookies for AdWords remarketing. Users can opt out via
          Google’s opt-out page.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">
          6. Use of Web Beacons
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          Mindcom Sites may contain web beacons to collect aggregated
          statistical data about site usage. Web beacons do not access personal
          information.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">7. Blogs</h1>
        <p className="text-lg text-gray-600 pl-3">
          Information disclosed on Mindcom-hosted blogs may become public and
          could be collected and used by others.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">8. Children</h1>
        <p className="text-lg text-gray-600 pl-3">
          If you are under 18, a parent or legal guardian must access and use
          Mindcom Sites on your behalf. Purchases must also be made by them.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">
          9. Limitation of Liability and Limited Guarantee
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          Mindcom Sites are provided "as is," with no warranties. Mindcom’s
          liability is limited to $100, and it is not liable for incidental,
          consequential, or punitive damages.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold text-gray-800">
          10. Questions and feedback
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          For questions or feedback about this Privacy Policy, please contact us
          at contact@mindcom. We will promptly investigate and address any
          issues.
        </p>
      </div>
      <div>
        <p className="text-lg text-gray-600 pl-3">
          This privacy policy was last updated on: May 10, 2024
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold pt-3 text-gray-800">
          Revisions to the Policy
        </h1>
        <p className="text-lg text-gray-600 pl-3">
          Changes to this Privacy Policy will be posted here. Material changes
          will be noted on our homepage for 30 days.
        </p>
      </div>
    </div>
  );
}
