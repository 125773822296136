// import React, { useState } from "react";

// export default function Form() {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     company: "",
//     message: "",
//     optIn: false,
//   });

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData({
//       ...formData,
//       [name]: type === "checkbox" ? checked : value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     emailjs
//       .send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", formData, "YOUR_USER_ID")
//       .then((response) => {
//         console.log("Success:", response);
//         alert("Message sent successfully!");
//       })
//       .catch((err) => {
//         console.error("Failed:", err);
//         alert("Failed to send message.");
//       });
//   };
//   return (
//     <div className="mx-auto p-4 max-w-4xl">
//       <form
//         className="grid grid-cols-1 md:grid-cols-2 gap-4"
//         onSubmit={handleSubmit}
//       >
//         <h2 className="col-span-full text-3xl font-bold text-sky-800 mb-4">
//           Contact Us
//         </h2>
//         <div className="flex flex-col">
//           <input
//             className="border p-2 text-black"
//             type="text"
//             name="firstName"
//             value={formData.firstName}
//             onChange={handleChange}
//             placeholder="Name*"
//             required
//           />
//         </div>
//         <div className="flex flex-col">
//           <input
//             className="border p-2 text-black"
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             placeholder="Email*"
//             required
//           />
//         </div>
//         <div className="flex flex-col">
//           <input
//             className="border p-2 text-black"
//             type="tel"
//             name="phone"
//             value={formData.phone}
//             onChange={handleChange}
//             placeholder="Phone Number"
//           />
//         </div>
//         <div className="flex flex-col">
//           <input
//             className="border p-2 text-black"
//             type="text"
//             name="company"
//             value={formData.company}
//             onChange={handleChange}
//             placeholder="Company"
//           />
//         </div>
//         <div className="col-span-full flex flex-col">
//           <textarea
//             className="border p-2 text-black"
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             rows="4"
//             placeholder="How Can We Help You"
//           ></textarea>
//         </div>
//         <div className="col-span-full flex items-center">
//           <input
//             className="mr-2"
//             type="checkbox"
//             name="optIn"
//             checked={formData.optIn}
//             onChange={handleChange}
//           />
//           <label className="text-black">
//             By opting in for text message, you agree to receive meassage from
//             VOIPOFFICE at the number period.Message frequency varies.Msg & data
//             rates may apply.View Our Privacy Policy for more information
//           </label>
//         </div>
//         <button
//           className="col-span-full border w-full py-2 bg-sky-700 hover:bg-lime-700 text-white"
//           type="submit"
//         >
//           SUBMIT
//         </button>
//       </form>
//     </div>
//   );
// }

import React, { useState } from "react";
import axios from "axios";

export default function Form() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    message: "",
    optIn: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mailgunData = {
      from: "Excited User <mailgun@sandbox1eebab544d2b4a4c8b2154fdbadbc511.mailgun.org>",
      to: [
        "bar@example.com",
        "YOU@sandbox1eebab544d2b4a4c8b2154fdbadbc511.mailgun.org",
      ],
      subject: "Hello",
      text: "Testing some Mailgun awesomeness!",
    };

    try {
      const response = await axios.post(
        "https://api.mailgun.net/v3/sandbox1eebab544d2b4a4c8b2154fdbadbc511.mailgun.org/messages",
        new URLSearchParams(mailgunData),
        {
          auth: {
            username: "api",
            password: "YOUR_API_KEY", // Replace with your Mailgun API key
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        alert("Message sent successfully!");
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send message.");
    }
  };

  return (
    <div className="mx-auto p-4 max-w-4xl">
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
        onSubmit={handleSubmit}
      >
        <h2 className="col-span-full text-3xl font-bold text-sky-800 mb-4">
          Contact Us
        </h2>
        <div className="flex flex-col">
          <input
            className="border p-2 text-black"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name*"
            required
          />
        </div>
        <div className="flex flex-col">
          <input
            className="border p-2 text-black"
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name*"
            required
          />
        </div>
        <div className="flex flex-col">
          <input
            className="border p-2 text-black"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email*"
            required
          />
        </div>
        <div className="flex flex-col">
          <input
            className="border p-2 text-black"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone Number"
          />
        </div>
        <div className="flex flex-col">
          <input
            className="border p-2 text-black"
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
            placeholder="Company"
          />
        </div>
        <div className="col-span-full flex flex-col">
          <textarea
            className="border p-2 text-black"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            placeholder="How Can We Help You"
          ></textarea>
        </div>
        <div className="col-span-full flex items-center">
          <input
            className="mr-2"
            type="checkbox"
            name="optIn"
            checked={formData.optIn}
            onChange={handleChange}
          />
          <label className="text-black">
            By opting in for text messages, you agree to receive messages from
            us. Message frequency varies. Msg & data rates may apply. View our
            Privacy Policy for more information.
          </label>
        </div>
        <button
          className="col-span-full border w-full py-2 bg-sky-700 hover:bg-lime-700 text-white"
          type="submit"
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
}
